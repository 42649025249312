<script lang="ts">
	import { onMount } from 'svelte';
	import netlifyIdentity from 'netlify-identity-widget';

	import home_spash from '$lib/images/home_spash.webp';

	onMount(() => {
		// Initialize Netlify Identity
		netlifyIdentity.init({});

		// Handle the invite token on page load
		const hash = window.location.hash;
		const match = hash.match(/invite_token=([^&]+)/);
		if (match) {
			const token = match[1]; // Extract the token from the match
			netlifyIdentity.confirm(token); // Confirm the user with the token
			window.location.hash = ''; // Clear the hash after confirmation
		}
	});
</script>

<svelte:head>
	<title>About Me - Michael Ekins, Software Engineer</title>
	<meta
		name="description"
		content="Welcome to my personal webpage! I'm a passionate software engineer with expertise in building web applications, mobile apps, and software tools. Explore my portfolio to see examples of my work and learn more about my skills and experience."
	/>
	<meta
		name="keywords"
		content="software engineer, web developer, portfolio, software projects, web applications, mobile apps, software tools"
	/>
	<meta property="og:type" content="website" />
	<meta property="og:title" content="About Me - Software Engineer Portfolio" />
	<meta
		property="og:description"
		content="Welcome to my personal webpage! I'm a passionate software engineer with expertise in building web applications, mobile apps, and software tools. Explore my portfolio to see examples of my work and learn more about my skills and experience."
	/>
	<meta property="og:url" content="https://michaelekins.co.uk" />
	<meta property="og:image" content="https://www.michaelekins.co.uk/favicon.ico" />
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:title" content="About Me - Michael Ekins, Software Engineer" />
	<meta
		name="twitter:description"
		content="Welcome to my personal webpage! I'm a passionate software engineer with expertise in building web applications, mobile apps, and software tools. Explore my portfolio to see examples of my work and learn more about my skills and experience."
	/>
	<meta name="twitter:image" content="https://www.michaelekins.co.uk/favicon.ico" />
</svelte:head>

<section>
	<div class="landing">
		<div class="col">
			<h1>Michael Ekins</h1>
			<p>Experienced software developer from Glasgow, Scotland.</p>
			<br />
			<p>Advocate of Agile &amp; Lean methodologies.</p>
		</div>
		<div class="col">
			<picture>
				<source srcset={home_spash} type="image/svg" />
				<img
					src={home_spash}
					alt="Duke of Wellington wearing a cone"
					height="var(--size-15)"
					width="auto"
				/>
			</picture>
		</div>
	</div>
</section>

<style>
	.landing {
		display: flex;
		align-items: center;
		margin-top: var(--size-10);
	}

	.landing h1 {
		font-size: var(--font-size-5);
		text-align: left;
		font-weight: var(--font-weight-3);
		margin-bottom: var(--size-8);
		margin-left: var(--size-3);
	}

	.landing p {
		font-weight: var(--font-weight-3);
		text-align: left;
		line-height: var(--font-lineheight-00);
	}

	.col {
		display: inline-block;
		max-width: 50%;
	}

	img {
		float: right;
		max-height: 20rem;
		margin-left: var(--size-10);
	}

	@media (max-width: 768px) {
		img {
			max-height: 10rem;
			margin-left: var(--size-5);
		}
	}
</style>
